<template>
  <div :class="$style.container">
    <router-link to="marketing-notifications/create" :class="$style.create">
      <Icon name="plus" :class="$style.plus" /> Создать уведомление
    </router-link>
    <ul :class="$style.filter">
      <li
        :class="[
          $style.item,
          { [$style.active]: 'Активные' === activeFilters },
        ]"
        @click="changeFilter('Активные')"
      >
        Активные
      </li>
      <li
        :class="[
          $style.item,
          { [$style.active]: 'Прошедшие' === activeFilters },
        ]"
        @click="changeFilter('Прошедшие')"
      >
        Прошедшие
      </li>
      <li
        :class="[$style.item, { [$style.active]: 'Все' === activeFilters }]"
        @click="changeFilter('Все')"
      >
        Все
      </li>
    </ul>
    <DraggableTable
      v-if="activeFilters === 'Активные'"
      :rows="rows"
      :items="notificationsList"
      @drag="handleDrag($event)"
    >
      <template v-slot:default="slotProps">
        <td v-if="slotProps.row.field === 'startAt'">
          {{ fomateDateRow(slotProps.item.startAt) }}
        </td>
        <td v-else-if="slotProps.row.field == 'endAt'">
          {{ fomateDateRow(slotProps.item.endAt) }}
        </td>
        <td v-else-if="slotProps.row.field == 'isNews'">
          <div
            :class="[
              $style.status,
              {
                [$style.successStatus]: slotProps.item.isNews,
              },
            ]"
          />
        </td>
        <td v-else-if="slotProps.row.field == 'forSuppliers'">
          <div
            :class="[
              $style.status,
              {
                [$style.successStatus]: slotProps.item.forSuppliers,
              },
            ]"
          />
        </td>
        <td v-else-if="slotProps.row.field == 'forDealers'">
          <div
            :class="[
              $style.status,
              {
                [$style.successStatus]: slotProps.item.forDealers,
              },
            ]"
          />
        </td>
        <td v-else-if="slotProps.row.field == 'forDealersBonusProgramMembers'">
          <div
            :class="[
              $style.status,
              {
                [$style.successStatus]:
                  slotProps.item.forDealersBonusProgramMembers,
              },
            ]"
          />
        </td>
        <td
          v-else-if="slotProps.row.field == 'forSuppliersBonusProgramMembers'"
        >
          <div
            :class="[
              $style.status,
              {
                [$style.successStatus]:
                  slotProps.item.forSuppliersBonusProgramMembers,
              },
            ]"
          />
        </td>
        <td v-else-if="slotProps.row.field === 'action'">
          <span>
            <router-link
              :to="
                getRoute({
                  route:
                    $options.ADDSELLERS_ADMIN_ROUTES.MARKETING_NOTIFICATIONS
                      .EDIT,
                  params: { id: slotProps.item.id },
                })
              "
            >
              <Icon name="plus" :class="$style.plus" />
            </router-link>
          </span>
        </td>
        <td v-else-if="slotProps.row.field === 'delete'">
          <span
            title="Удалить акцию"
            @click="deleteMarketingNotification(slotProps.item)"
          >
            <Icon name="plus" :class="$style.delete" />
          </span>
        </td>
      </template>
    </DraggableTable>
    <vue-good-table
      v-else
      :class="$style.table"
      :columns="columns"
      :rows="notificationsList"
      styleClass="vgt-table striped"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'startAt'">
          {{ fomateDateRow(props.row.startAt) }}
        </span>
        <span v-else-if="props.column.field == 'endAt'">
          {{ fomateDateRow(props.row.endAt) }}
        </span>
        <span v-else-if="props.column.field == 'isNews'">
          <div
            :class="[
              $style.status,
              {
                [$style.successStatus]: props.row.isNews,
              },
            ]"
          />
        </span>
        <span v-else-if="props.column.field == 'forSuppliers'">
          <div
            :class="[
              $style.status,
              {
                [$style.successStatus]: props.row.forSuppliers,
              },
            ]"
          />
        </span>
        <span v-else-if="props.column.field == 'forDealers'">
          <div
            :class="[
              $style.status,
              {
                [$style.successStatus]: props.row.forDealers,
              },
            ]"
          />
        </span>
        <span v-else-if="props.column.field == 'forDealersBonusProgramMembers'">
          <div
            :class="[
              $style.status,
              {
                [$style.successStatus]: props.row.forBonusProgramMembers,
              },
            ]"
          />
        </span>
        <span
          v-else-if="props.column.field == 'forSuppliersBonusProgramMembers'"
        >
          <div
            :class="[
              $style.status,
              {
                [$style.successStatus]: props.row.forBonusProgramMembers,
              },
            ]"
          />
        </span>
        <span v-else-if="props.column.field == 'action'">
          <router-link :to="`marketing-notifications/edit/${props.row.id}`">
            <Icon name="plus" :class="$style.plus" />
          </router-link>
        </span>
        <span
          v-else-if="props.column.field == 'delete'"
          title="Удалить акцию"
          @click="deleteMarketingNotification(props.row)"
        >
          <Icon name="plus" :class="$style.delete" />
        </span>
      </template>
    </vue-good-table>
    <Button
      v-if="activeFilters === 'Активные'"
      :class="$style.loadMore"
      @click="loadMore"
    >
      Загрузить еще</Button
    >
    <Pagination
      v-if="pageCount > 1 && activeFilters !== 'Активные'"
      @go-next="goNext()"
      @go-prev="goPrev()"
      :curPage="notificationPage"
      :lastPage="pageCount"
    />
    <Loader :isLoading="isLoading" />
  </div>
</template>

<script>
import delivery from '@/delivery'
import notifications from '@/mixins/notifications'

import { formatDate } from '@/helpers/index.js'
import { ADDSELLERS_ADMIN_ROUTES, getRoute } from '@/constants/routing'

import Loader from '@/components/atoms/LoadingBar.vue'
import Button from '@/components/atoms/Button.vue'
import DraggableTable from '@/components/moleculs/DraggableTable.vue'
import Pagination from '@/components/atoms/Paginations.vue'
import Icon from '@/components/atoms/Icon'

export default {
  ADDSELLERS_ADMIN_ROUTES,
  mixins: [notifications],
  components: { Icon, Loader, DraggableTable, Pagination, Button },
  mounted() {
    this.changeFilter(this.activeFilters)
  },
  data() {
    return {
      columns: [
        {
          label: 'Название акции',
          field: 'title',
          tooltip: 'Название акции',
          sortable: false,
        },

        {
          label: 'Дата начала',
          field: 'startAt',
          sortable: false,
        },
        {
          label: 'Дата окончания',
          field: 'endAt',
          sortable: false,
        },
        {
          label: 'Лента',
          field: 'isNews',
          isSlot: true,
          sortable: false,
        },
        {
          label: 'Поставщикам не участвующим в БП',
          field: 'forSuppliers',
          isSlot: true,
          sortable: false,
        },
        {
          label: 'Дилерам не участвующим в БП',
          field: 'forDealers',
          isSlot: true,
          sortable: false,
        },
        {
          label: 'Дилерам участникам БП',
          field: 'forDealersBonusProgramMembers',
          isSlot: true,
          sortable: false,
        },
        {
          label: 'Поставщикам участникам БП',
          field: 'forSuppliersBonusProgramMembers',
          isSlot: true,
          sortable: false,
        },
        {
          label: '',
          field: 'action',
          sortable: false,
        },
        {
          label: '',
          field: 'delete',
          sortable: false,
        },
      ],
      rows: [
        {
          label: 'Название акции',
          field: 'title',
        },
        {
          label: 'Дата начала',
          field: 'startAt',
          isSlot: true,
        },
        {
          label: 'Дата окончания',
          field: 'endAt',
          isSlot: true,
        },
        {
          label: 'Лента',
          field: 'isNews',
          isSlot: true,
        },
        {
          label: 'Поставщикам не участвующим в БП',
          field: 'forSuppliers',
          isSlot: true,
        },
        {
          label: 'Дилерам не участвующим в БП',
          field: 'forDealers',
          isSlot: true,
        },
        {
          label: 'Дилерам участникам БП',
          field: 'forDealersBonusProgramMembers',
          isSlot: true,
        },
        {
          label: 'Поставщикам участникам БП',
          field: 'forSuppliersBonusProgramMembers',
          isSlot: true,
        },
        {
          label: '',
          field: 'action',
          isSlot: true,
        },
        {
          label: '',
          field: 'delete',
          isSlot: true,
        },
      ],
      activeFilters: 'Активные',
      notificationsList: [],
      isLoading: false,
      notificationPage: 1,
      notificationTotal: 0,
      notificationLimit: 10,
      orderBy: 'order',
      orderDir: 'asc',
    }
  },
  computed: {
    pageCount() {
      return Math.ceil(this.notificationTotal / this.notificationLimit)
    },
  },
  methods: {
    async getMarketingNotification(expired = null) {
      this.isLoading = true

      const { value, error } =
        await delivery.AddwineCore.MarketingNotificationActions.getList(
          expired,
          this.notificationPage,
          this.notificationLimit,
          this.orderBy,
          this.orderDir,
        )

      this.isLoading = false

      if (error) return

      this.notificationsList = value.data
      this.notificationTotal = value.meta.count
    },
    fomateDateRow(date) {
      return formatDate(date)
    },
    async deleteMarketingNotification(notification) {
      if (
        confirm(
          `Вы действительно хотите навсегда удалить маркетинговое уведомление "${notification.title}"?`,
        )
      ) {
        const { error } =
          await delivery.AddwineCore.MarketingNotificationActions.delete(
            notification.id,
          )
        if (error) {
          this.showNotification('Ошибка удаления акции', 'error')
          return
        }

        this.showNotification('Акция успешно удалена', 'success')
        this.changeFilter(this.activeFilters)
      }
    },
    async changeFilter(activeFilter) {
      this.activeFilters = activeFilter
      this.notificationPage = 1
      switch (activeFilter) {
        case 'Активные':
          this.orderBy = 'order'
          this.orderDir = 'asc'
          await this.getMarketingNotification(false)
          break
        case 'Прошедшие':
          this.orderBy = 'start_at'
          this.orderDir = 'desc'
          await this.getMarketingNotification(true)
          break
        case 'Все':
          this.orderBy = 'start_at'
          this.orderDir = 'desc'
          await this.getMarketingNotification()
          break
        default:
          this.orderBy = 'order'
          this.orderDir = 'asc'
          this.activeFilters = 'Активные'
          await this.getMarketingNotification(false)
      }
    },
    async goNext() {
      if (this.notificationPage < this.pageCount) {
        this.notificationPage = this.notificationPage + 1
        switch (this.activeFilters) {
          case 'Прошедшие':
            await this.getMarketingNotification(true)
            break
          case 'Все':
            await this.getMarketingNotification()
            break
          default:
            await this.getMarketingNotification(true)
        }
      }
    },
    async goPrev() {
      if (this.notificationPage > 1) {
        this.notificationPage = this.notificationPage - 1
        switch (this.activeFilters) {
          case 'Прошедшие':
            await this.getMarketingNotification(true)
            break
          case 'Все':
            await this.getMarketingNotification()
            break
          default:
            await this.getMarketingNotification(true)
        }
      }
    },
    async updateOrders() {
      this.isLoading = true
      const result =
        await delivery.AddwineCore.MarketingNotificationActions.updateOrders({
          notifications: this.notificationsList,
        })
      if (result.error) return
      this.isLoading = false
    },
    async handleDrag($event) {
      this.notificationsList = $event
      await this.updateOrders()
    },
    async loadMore() {
      this.notificationPage++
      const prevArr = this.notificationsList
      await this.getMarketingNotification(false)
      this.notificationsList = [...prevArr, ...this.notificationsList]
    },
    getRoute({ route, params }) {
      return getRoute({ route, params })
    },
  },
}
</script>

<style lang="scss" module>
.container {
  padding: 1rem;
  .status {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: red;
  }
  .successStatus {
    background-color: green;
  }
  .create {
    display: inline-flex;
    align-items: center;
    padding: 0.5rem 1rem;
    border: 1px solid $light-gray;
    margin-bottom: 2rem;
    gap: 0.5rem;
    text-decoration: none;

    color: $dark-gray;
    &:hover {
      background: $white;
    }
  }
  .filter {
    display: flex;
    margin-bottom: 2rem;
    padding: 0;
    .item {
      margin-right: 1.5rem;
      color: $light-gray;
      cursor: pointer;
      position: relative;
      list-style-type: none;
      &.active {
        color: $black-gray;
        &::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: -0.5rem;
          height: 0.25rem;
          width: 100%;
          background: $black-gray;
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .loadMore {
    display: flex;
    margin: auto;
    margin-top: 2rem;
  }
  h2 {
    padding: 1rem 0;
  }
  .plus {
    fill: $light-gray;
    width: 1rem;
    height: 1rem;
  }
  .delete {
    fill: $light-gray;
    cursor: pointer;
    width: 1rem;
    height: 1rem;
    transform: rotate(45deg);
  }
}
</style>
